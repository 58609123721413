<template>
  <div id="app">
    <NavigationBar>
    </NavigationBar>
    <router-view />
    <Backtop></Backtop>
  </div>
</template>

<script>
import NavigationBar from './components/NavigationBar.vue';
import Backtop from './components/Backtop.vue';

export default {
  name: 'App',
  components: {
    NavigationBar,
    Backtop
  },
  mounted() {
    // 添加鼠标滚轮事件监听器
    window.addEventListener('wheel', this.handleWheel, { passive: false });
  },
  beforeDestroy() {
    // 移除事件监听器，避免内存泄漏
    window.removeEventListener('wheel', this.handleWheel, { passive: false });
  },
  methods: {
    handleWheel(event) {
      // 检查是否按下了Ctrl键或Meta键（Mac上的Command键）
      if (event.ctrlKey || event.metaKey) {
        // 阻止默认行为，即阻止页面缩放
        event.preventDefault();
      }
      // 允许正常的滚动操作
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* width: 100%; */
}

@media (max-width: 600px) {
  #app {
    width: 1200px;
    min-height: 2200px
  }
}
</style>