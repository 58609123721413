<template>
  <div>
    <el-header class="header" v-if="currentPath !== '/login'">
      <img src="@/assets/images/logo.png" class="nav_logo" />
      <el-menu :router="true" :default-active="currentPath" class="el-menu-demo" mode="horizontal"
        @select="handleSelect">
        <el-menu-item index="/">首页</el-menu-item>
        <el-menu-item index="/product">产品中心</el-menu-item>
        <el-menu-item index="/usaco">USACO</el-menu-item>
        <el-menu-item index="/download">软件下载</el-menu-item>
        <el-menu-item index="/about">关于我们</el-menu-item>
        <el-menu-item>
          <el-button type="primary" size="mini" round @click="goSkOJ">学习中心</el-button>
        </el-menu-item>
      </el-menu>
    </el-header>
  </div>
</template>

<script>
export default {
  created() {
    this.currentPath = window.location.pathname === '/home' ? '/' : window.location.pathname
  },
  methods: {
    handleSelect(index) {
      this.currentPath = index
    },
    goSkOJ() {
      window.open('http://oj.skcoding.com', '_blank');
    }
  }
}
</script>

<style lang="scss" scoped>
.el-header {
  position: absolute;
  top: 0;
  z-index: 22;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 3%;
  align-items: center;

  .book_test_btn {
    margin-top: 14px;
  }
}

.el-button--primary {
  font-size: 15px;
  background-color: #8273e5;
  border-color: #8273e5;
}

.el-button--primary:hover {
  background-color: #8273e5;
  border-color: #8273e5;
}

.el-menu {
  background-color: rgba(255, 255, 255, 0);
}

.el-menu.el-menu--horizontal {
  display: flex;
  // padding: 0 100px;
  border-bottom: 0;
}

.el-menu--horizontal>.el-menu-item {
  font-size: 18px;
  color: #fff;
  letter-spacing: 0.2em;
}

.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 2px solid rgba(255, 255, 255, 0);
  color: #fff;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):focus {
  color: #fff;

}

.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: #97aaee;
}

.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal>.el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal>.el-submenu .el-submenu__title:hover {
  background-color: transparent;
}

.nav_logo {
  margin-left: -100px;
  ;
}
</style>
